/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/core.css';
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./app/app.css";


.mat-form-field, .mat-button, .mat-fab, .mat-flat-button, .mat-icon-button, .mat-mini-fab, .mat-raised-button, .mat-stroked-button, .mat-table, .mat-tab-label, .mat-tab-link, .mat-select, .mat-radio-label-content{
    font-family: Open Sans,arial,tahoma,verdana,sans-serif !important;
}
*{}
.mat-icon, .material-icons{
	font-family: 'Material Icons';
} 

ion-title {
    font-size: var(--font-size-16px);
    text-transform: uppercase;
}
ion-button.footerbtn .button-native{margin-bottom:0px;}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{border-color:var(--ion-color-light);}
.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{background-color: var(--ion-color-primary);}
.mat-radio-label-content{color: var(--ion-color-light)}
.picker-opts{
    max-width: 100% !important;
}
.phoneinput .input-main-div .CountryNumberInput{
	height:28px !important;
}

 .select2-results__options{
	width: 248px !important;
}

app-login .mat-button[disabled][disabled]{color:#ffffff;    border: 2px solid #0e3b60;}
.bookings .mat-tab-label-active {
    background-color: var(--dblue);
}





/* Dark Theme */
ion-content{
	--background:transparent;
}
.mat-radio-outer-circle{
	border-color:#ffffff;
}
app-contact-us{
	mat-form-field label{color: #b4b4b4 !important;}
}
mat-form-field{
	label{color: var(--color-white-content) !important;}
}
.bookings .mat-tab-label{    color: #ffffff;}
app-contact .bookings .mat-tab-label{color: #010101;}
app-contact .bookings .mat-tab-label.mat-tab-label-active{color: #ffffff;}
.bookings .mat-tab-label h5{ font-family: var(--ion-font-family, inherit);}


app-contact-us ::placeholder, app-select-location::placeholder{color: #ffffff;}



.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color:transparent;
	color:#000;
    padding: 0px;
}
#mat-select-0-panel {
    margin-left: 4px;
    width: 130px;
    margin-top: 41px;
}
.mat-form-field-infix {bottom:5px;}
.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
   display: block;
}
.mat-select-arrow {color:#000;}
.mat-form-field-underline {width: 0px !important;}
.mat-form-field-hide-placeholder .mat-select-placeholder {color:#000;}

app-address-multiple{
    button{span{font-family: var(--ion-font-family);}}
    .mat-radio-container{margin-top: 0px;}
    .mat-radio-outer-circle{border:1px solid #010101;}
}

app-service-detail, app-select-datetime{
    button{span{font-family: var(--ion-font-family);}}
}

@media only screen and (max-width:1024px){
	.button-web-view{display: none !important;}
	
}


.multipal-Address .mat-radio-label-content{
    display: flex;
    align-items: center;
}
app-home-new form .mat-form-field-appearance-fill .mat-form-field-infix {
bottom: 8px;}

.somethingelseservicedetail{
    mat-dialog-container{padding: 0px;}
    mat-form-field{
        label{color: #010101 !important;}
        .mat-form-field-wrapper{padding-bottom: 0px;}
    }
}