.select-datetime .mat-radio-label, .choose-functionality .mat-radio-label{
	width:100%;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.select-datetime .mat-radio-label-content, .choose-functionality .mat-radio-label-content{
	width:100%;
	position: relative;
	margin-left: 20px;
}
.select-datetime .mat-radio-label-content{
    color: var(--ion-color-primary);
}
.address-select label.mat-radio-label, .address-select .mat-radio-label-content{
    width: 100%;
}
.choose-functionality .mat-radio-label-content:after{
	content: '';
	position: absolute;
    background-color: rgba(0,0,0,.12);
    display: block;
    z-index: 15;
    top: auto;
    right: auto;
    bottom: -13px;
    left: 9px;
    height: 1px;
    width: calc( 100% - 18px );
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
	
}
.bookings .mat-tab-label{
    width:50%;
    text-align: center;
    min-height: 65px;
}
.bookings .mat-tab-label-active{
    background-color: var(--ion-color-primary);
    color: #ffffff;
    border-bottom: 1px solid var(--ion-color-primary);
    opacity: 1;
    min-height: 65px;
}
.bookings h5{
                margin-top:5px;
                margin-bottom:1px;
            }
.bookings .mat-tab-label .mat-tab-label-content{
    display: block;
}
.bookings .mat-tab-group.mat-primary .mat-ink-bar{
    background-color: var(--ion-color-primary);
}
ion-button.footerbtn .button-native{margin-bottom:0px;}
.searchbar-input.sc-ion-searchbar-md{
    padding: 1px 55px;
    border-radius: 10px;
}
.searchbar-search-icon.sc-ion-searchbar-md{
    top: 6px;
}
.dividercontact {                              /* minor cosmetics */
    display: table; 
    font-size: var(--font-size-16px); 
    text-align: center; 
    width: 75%;                         /* divider width */
    margin: 40px auto 20px;                  /* spacing above/below */
}
.dividercontact span { display: table-cell; position: relative; }
.dividercontact span:first-child, .dividercontact span:last-child {
    width: 50%;
    top: 10px;                          /* adjust vertical align */
    -moz-background-size: 100% 2px;     /* line width */
    background-size: 100% 2px;          /* line width */
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
}
.dividercontact span:first-child {             /* color changes in here */
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), to(#000));
    background-image: -webkit-linear-gradient(180deg, transparent, #000);
    background-image: -moz-linear-gradient(180deg, transparent, #000);
    background-image: -o-linear-gradient(180deg, transparent, #000);
    background-image: linear-gradient(90deg, transparent, #000);
}
.dividercontact span:nth-child(2) {
    color: var(--ion-color-tertiary); padding: 0px 5px; width: auto; white-space: nowrap;font-weight: 700;
}
.dividercontact span:last-child {              /* color changes in here */
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#000), to(transparent));
    background-image: -webkit-linear-gradient(180deg, #000, transparent);
    background-image: -moz-linear-gradient(180deg, #000, transparent);
    background-image: -o-linear-gradient(180deg, #000, transparent);
    background-image: linear-gradient(90deg, #000, transparent);
}
