
// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
// f44336

/** Ionic CSS Variables **/
:root {
  /** primary **/
  //Actual Color Code
  /* --ion-color-primary: #ff0000;
  --ion-color-primary-rgb: 255,0,0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #5a0087;
  --ion-color-primary-tint: #751aa3;


  --ion-color-secondary: #ffffff;
  --ion-color-secondary-rgb: 255,255,255;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #e0e0e0;
  --ion-color-secondary-tint: #ffffff;

  --ion-color-tertiary: #000000;
  --ion-color-tertiary-rgb: 0,0,0;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #000000;
  --ion-color-tertiary-tint: #1a1a1a;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --color-light-grey:#aeaeae;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #00003a;
  --ion-color-light-rgb: 27,43,66;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #cacaca;
  --ion-color-light-tint: #e8e8e8; */

  // New color code
  --ion-color-primary: #ff0000;
  --ion-color-primary-rgb: 255,0,0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80,200,255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106,100,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47,223,117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255,213,52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255,73,97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244,245,248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34,36,40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;


  --color-white-content:#ffffff;
  --color-grey-content:#949494;
  --color-greybackground:#242528;
  --color-active-tabs:#002060;
  --icon-color-dark-theme:#969698;
  --blackcolor-textwhitebox:#010101;
  --new-color:#010f31;
  --new-button-color:#002FA6;
  //--new-button-color:#010f31;

  // End New Color code
  
  --ion-font-family: 'Montserrat', sans-serif;
  --light-white: #fefefe;
  --linear-gradient-about:linear-gradient(#559dda, #000000);
  --theme-light:#550080;
  --grey:#eaeaea;
  --black:#000;  

  //ion-avatar-bg
  --ion-color-lightish:#eeccff;

  --light-white-bg:#eef2f9;
  --ion-color-secondone:#eb1849;
  --ion-color-white:#ffffff;
  --dblue:#0e3b60;
  --text-color:#66666d;
  --varcolortheme: #ff0000;
  
  //https://www.w3schools.com/tags/ref_pxtoemconversion.asp 16-1em
  --font-size-25px: 1.5625em;
  --font-size-24px: 1.5000em;
  --font-size-22px: 1.3750em;
  --font-size-21px: 1.3125em;
  --font-siez-20px: 1.2500em;
  --font-size-19px: 1.1875em;
  --font-size-18px: 1.1250em;
  --font-size-16px: 1em;
  --font-size-15px: 0.9375em;
  --font-size-14px: 0.8750em;
  --font-size-13px: 0.8125em;
  --font-size-12px: 0.7500em;
  --font-size-11px: 0.688em;
  --font-size-10px: 0.625em;
  --font-size-9px: 0.562em;
}
:root {
  --header-btn:#fff;
  --banner:#fff;
  --list-btn:#fff;
  --list:#fff;
  --service:#fff;
  --assets:#212121;
  --offers:#757575;
  --offers-titel:#212121;
  --invite-title:#757575;
  --mat-form-field:#fff;
  --submit:#212121;
  --footer:#000;
  --offers-image:#757575;
  --Serving:#fff;
  --Footer-nav-list:#fff;
  --Footer-nav-aus:#fff;
  --Footer-nav:#fff;
  --uae:#fff;
  --SGP:#fff;
  --KAS:#fff;
  --footer-logo:#fff;
  --footer-navber:#fff;
  --Footer-icon:#fff;
  }